import React from "react";




function Footer() {
  return (
    <footer>
      <div className="col-1">
        <div className="logo">
          <a href="#">
            <img src="images/tfklogo2.png" alt="index.html" />
          </a>
        </div>
        <p>Let's close the digital divide. </p>
      </div>
      <div className="col-2">
        <h2 className="column-heading">Menu</h2>
        <ul>
          <li>
            <a href="">Home</a>
          </li>
          <li>
            <a href="about">About</a>
          </li>
          <li>
            <a href="apply">Apply</a>
          </li>
          <li>
            <a href="faq">FAQs</a>
          </li>
          <li>
            <a href="contact">Contact</a>
          </li>
        </ul>
      </div>
      <div className="col-3">
        <h2 className="column-heading">
          Contact
          <br />
          Info
        </h2>
        <ul>
          <li>
            <a href="mailto:techforkidshelp@gmail.com">techforkidshelp@gmail.com</a>
          </li>
        </ul>
      </div>
     

    </footer>
  );
}

export default Footer;
