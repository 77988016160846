import { React, useRef } from "react";
import emailjs from "@emailjs/browser";
import Navbar from "./navbar";
import Footer from "./footer";



const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lagdgjj",
        "template_b54cq38",
        form.current,
        "wrzMWDoC1r3S5wH8g"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section>
      <div className="topic-text">Send us a message</div>
      <p>
        If you have any questions about tech for kids, you can send us a message
        from here.{" "}
      </p>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="input-box">
          <input
            type="text"
            name="user_name"
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="input-box">
          <input
            type="text"
            name="user_email"
            placeholder="Enter your email address"
            required
          />
        </div>
        <div className="input-box">
          <input
            type="text"
            rows="1"
            name="subject"
            placeholder="Subject"
            required
          />
        </div>
        <div className="input-box message-box">
          <textarea name="message" rows="8"></textarea>
        </div>
        <div className="button">
          <button type="submit">Send Message</button>
        </div>
      </form>
    </section>
  );
};

function contact() {
  return (
    <div>
      <Navbar />

      <section id="contactinfo">
        <div className="container">
          <div className="left">
            <h2 className="heading">
              <div className="wrapper">
                <span>CONTACT US</span>
              </div>
              <div className="h"></div>
            </h2>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="container">
          <div className="content">
            <div className="left-side">
              <div className="phone details">
                <i className="fas fa-phone-alt"></i>
                <div className="topic">Phone</div>
                <div className="text-one">+650 229 4781</div>
              </div>
              <div className="email details">
                <i className="fas fa-envelope"></i>
                <div className="topic">Email</div>
                <div className="text-one">techforkidshelp@gmail.com</div>
              </div>
            </div>

            <div className="right-side">
              <Contact />
            </div>
          </div>
        </div>
      </section>

      <section id="footer">
        <div className="container">
          <Footer />
        </div>
      </section>

      <script src="js/smooth-scroll.js"></script>

      <script>
        var Scrollbar = window.Scrollbar;
        Scrollbar.init(document.querySelector('#about-page'));
      </script>
    </div>
  );
}

export default contact;
