import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";

function about() {
  return (
    <div>
      <Navbar />

      <section id="about-hero">
        <div className="about-hero container">
          <div>
            <h1>
              About Us<span></span>
            </h1>
          </div>
        </div>
      </section>

      <section id="purpose">
        <div className="container">
          <div className="left">
            <h2 className="heading">
              <div className="wrapper">
                <span>Our Purpose</span>
              </div>
            </h2>
            <p className="desc">
              Tech for Kids hopes to help close the digital divide for children
              within California and do everything in its power to do so. The
              Digital divide is a significant social issue that places people at
              a disadvantage. Many have linked the digital divide to poor
              economic and social outcomes, such as fewer job opportunities or
              lower student performances. In addition, it also increases
              existing inequalities along racial and other social lines.{" "}
            </p>
          </div>
          <div className="right">
            <img src="images/aboutusimage.jpg" alt="" />
          </div>
        </div>
      </section>

      <section id="story">
        <div className="container">
          <div className="right">
            <img src="images/story.jpg" alt="" />
          </div>
          <div className="left">
            <h2 className="heading">
              <div className="wrapper">
                <span>Our Story</span>
              </div>
             
            </h2>
            <p className="desc">
              After hearing stories about children who struggled at school due
              to not having computers, a group of students decided to join
              together in order to address this issue and bring technology to
              children in California.{" "}
            </p>
          </div>
        </div>
      </section>

      <section id="footer">
        <div className="container">
          <Footer />
        </div>
      </section>

      <script src="js/smooth-scroll.js"></script>

      <script>
        var Scrollbar = window.Scrollbar;
        Scrollbar.init(document.querySelector('#about-page'));
      </script>
    </div>
  );
}

export default about;
