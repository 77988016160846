import "./style.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./components/main";
import About from "./components/about";
import Apply from "./components/apply";
import Donate from "./components/donate";
import Faq from "./components/faq";
import Contact from "./components/contact";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
