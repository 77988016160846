import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";





function donate() {
  return (
    <div>
      <Navbar />

      <section id="donate-hero">
        <div className="donate-hero container">
          <div>
            <h1>
              Donate<span></span>
            </h1>
          </div>
        </div>
      </section>

      <section id="donatehow">
        <h2 className="heading">
          <div className="wrapper">
            <span>How Can I Donate?</span>
          </div>
        </h2>
        <div className="container">
          <div className="left">
            <p className="headdesc">
              <br></br>
              There are two ways you can donate a computer. {" "}
            </p>

            <p className="headdesc">
              <br></br>
              Given our limited resources, we can only accept laptops that are not older than four years old. {" "}
              <br></br>
              At this time, we cannot accept ipads, tablets, desktops, and computers that are older than four years old. {" "}
            </p>

            <p className="desc">
              <h4>Method 1: We pick it up from your house </h4>
              <br></br>
              Fill out the form listed below. This will provide all the
              information we need in order to pick up your computer.  {" "}
            </p>
            <p className="desc">
              <h4>
                Method 2: Come to our monthly donation stands. The location
                varies every month and we will get you notified via email.{" "}
              </h4>
            </p>
          <div className = "buttons">
            <a href="https://forms.gle/ttTMbynGqSGwS2qG8" target="_blank" rel="noopener noreferrer">
              <button>Pick Up</button>
            </a>

            <a href="https://forms.gle/3vKCnMHRoLngAJBX8" target="_blank" rel="noopener noreferrer">
              <button>Sign Up For Emails</button>
            </a>
          </div>
          </div>
        </div>

        <div className="h"></div>
      </section>

      <section id="donateinfo">
        <h2 className="heading">
          <div className="wrapper">
            <span>What Happens to My Computer?</span>
          </div>
        </h2>
        <div className="container">
          <div className="left">
            <p className="desc">
              <h4>Step 1: </h4>
              <br></br>
              Unreusable hard drives get destroyed. The computer has its data
              destroyed and everything on the hard drive is erased. {" "}
            </p>
            <p className="desc">
              <h4>Step 2: </h4>
              <br></br>
              We then load a new windows operating system, anti-virus software
              and other important programs into the device .{" "}
            </p>
            <p className="desc">
              <h4>Step 3: </h4>
              <br></br>
              The final stage involves checking the computer's components, to
              ensure that everything is working.
            </p>
          </div>
        </div>

        <div className="h"></div>
      </section>

      <section className="why">
        <h1>Why should you donate</h1>

        <div className="row">
          <div className="course-col">
            <i className="fa-solid fa-school"></i>
            <h4>Support a good cause</h4>
            <p>
              One of the biggest reasons to donate is to help those in need. By
              donating your computer, you children that are less fortunate and help close the digital divide.
            </p>
          </div>

          <div className="course-col">
          <i class="fa-solid fa-trash"></i>
            <h4>Reduce Clutter In Your House</h4>

            <p>
              You no longer have to worry about the computers you have lying around. Donating a computer with us is quick and easy. Just drop it off or schedule a pickup time and you're done. 
            </p>
          </div>

          <div className="course-col">
            <i className="fa-solid fa-seedling"></i>

            <h4>Good for the environment</h4>
            <p>
              Most of time, recycling your computer ends up in landfill. The
              computer may have toxic material within it such as arsenic, lead,
              and cadmium. If these materials make it into the landfill, then it
              can negatively impact the environment and local
              communities.
            </p>
          </div>
        </div>
      </section>

      <section id="footer">
        <div className="container">
          <Footer />
        </div>
      </section>

      <script src="js/smooth-scroll.js"></script>

      <script>
        var Scrollbar = window.Scrollbar;
        Scrollbar.init(document.querySelector('#about-page'));
      </script>
    </div>
  );
}

export default donate;
