import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";




function faq() {
  return (
    <div>
      <Navbar />

      <section id="faqhero">
        <div className="container">
          <div className="left">
            <h2 className="heading">
              <div className="wrapper">
                <span>FAQs</span>
              </div>
            </h2>
            <p className="desc">
              Can't find the answer you're looking for? We have shared some of
              the most asked questions to help you out!
            </p>
          </div>
        </div>
      </section>

      <section id="faqinfo">
        <div className="container">
          <div className="left">
            <h2 className="heading">
              <p className="desc">
                <h4>What computer equipment is best to donate? </h4>
                <br></br>
                Any laptop that is not older than 4 years old that
                can both work and access the internet would be best to donate to children. Please note that we do not accept desktops or phones/tablets. {" "}
              </p>
              <p className="desc">
                <h4>I need a computer! How do I apply? </h4>
                <br></br>
                If you are interested in acquiring a computer, check out the
                apply page. This page will take you through all the steps you
                need to get yourself a computer. If you have any questions you
                can use the contact page to email us or call us.{" "}
              </p>
              <p className="desc">
                <h4>What should I do before I donate my computer? </h4>
                <br></br>
                If you would like, you could erase all of the memory on your
                computer by yourself. However, at Tech for kids, we remove all
                data from all devices based on Dept of the standard of Defense
                (DOD) 5220.22-M.
              </p>
              <p className="desc">
                <h4>How does the charity operate?</h4>
                <br></br>
                The charity operates by collecting donated computers and then
                distributing them to children in need.
              </p>
            </h2>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="container">
          <Footer />
        </div>
      </section>

      <script src="js/smooth-scroll.js"></script>

      <script>
        var Scrollbar = window.Scrollbar;
        Scrollbar.init(document.querySelector('#about-page'));
      </script>
    </div>
  );
}

export default faq;
