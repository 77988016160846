import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./navbar";
import Footer from "./footer";



function main() {
  return (
    <div className="App">
      <div className="main">
        <Navbar />
        <section id="hero">
          <div class="hero container">
            <div>
              <h1>
                HELP US CLOSE<span></span>
              </h1>
              <h1>
                THE DIGITAL DIVIDE<span></span>
              </h1>
              <a href="/donate" type="button" class="cta">
                Donate A Computer Today
              </a>
            </div>
          </div>
        </section>

        <section id="about">
          <div className="container">
            <div className="left">
              <h2 className="heading">
                <div className="wrapper">
                  <span>About Tech</span>
                </div>
                <div className="wrapper">
                  <span>For Kids</span>
                </div>
              </h2>
              <p className="desc">
                Tech for Kids is currently in the process of being registered as a 501(c)(3). We are a public charity that provides free
                computer equipment to children in need. We are
                committed to closing the digital divide by providing computers
                to those who would not otherwise have access to them. We invite
                anyone that has a computer they do not need, to donate today!{" "}
              </p>
              <div className="buttons">
                <a href="/about">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className="right">
              <img src="images/about.png" alt="" />
            </div>
          </div>
        </section>

        <section id="apply">
          <div className="container">
            <div className="right">
              <img src="images/applys.png" alt="" />
            </div>
            <div className="left">
              <h2 className="heading">
                <div className="wrapper">
                  <span>Can You</span>
                </div>
                <div className="wrapper">
                  <span>Apply?</span>
                </div>
              </h2>
              <p className="desc">
                Because of our limited supply, we can only provide our computers
                for kids in California. If you are interested in applying for a
                computer, please check out the page below to learn about the
                proper documentation.{" "}
              </p>
              <div className="buttons">
                <a href="/apply">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="what">
          <div className="container">
            <div className="left">
              <h2 className="heading">
                <div className="wrapper">
                  <span>How To</span>
                </div>
                <div className="wrapper">
                  <span>Donate</span>
                </div>
              </h2>
              <p className="desc">
                Please note that we only accept laptops that are not older than four years old. If you would like to donate a computer, all you need to do is
                fill out a few forms. To ensure your information is safe, we
                have high standards for data destruction.{" "}
              </p>
              <div className="buttons">
                <a href="/donate">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className="right">
              <img src="images/what.png" alt="" />
            </div>
          </div>
        </section>

        <section id="footer">
          <div className="container">
            <Footer />
          </div>
        </section>
      </div>
    </div>
  );
}

export default main;
