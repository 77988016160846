import { React, useState } from "react";
import MobileNavigation from "./mobileNavigation";
import { CgMenu } from "react-icons/cg";
import { CgCloseO } from "react-icons/cg";

function Navbar() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const hamburgerIcon = (
    <CgMenu
      className="burger"
      size="40px"
      color="#0a253b"
      onClick={() => toggleHamburger()}
    />
  );
  const closeIcon = (
    <CgCloseO
      className="burger"
      size="40px"
      color="#0a253b"
      onClick={() => toggleHamburger()}
    />
  );



  return (
    <nav>
      <div className="logo">
        <a href="/">
          <img src="images/tfklogo1.png" alt="index.html" />
        </a>
      </div>
      <ul className="nav-links">
        <li>
          <a href="/apply">Apply</a>
        </li>
        <li>
          <a href="/donate">Donate</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/faq">FAQs</a>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
      </ul>
      {hamburgerOpen ? closeIcon : hamburgerIcon}
      {hamburgerOpen && <MobileNavigation />}
    </nav>
  );
}

export default Navbar;
