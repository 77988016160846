import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";



function apply() {
  return (
    <div>
      <Navbar />

      <section id="apply-hero">
        <div className="apply-hero container">
          <div>
            <h1>
              Apply<span></span>
            </h1>
          </div>
        </div>
      </section>

      <section id="applyinfo">
        <div className="container">
          <div className="left">
            <p className="desc">
              We welcome anyone to receive a working computer. However, due to
              our limited supply, we have to check a number of factors for
              program qualification. Tech for Kids requires income documentation
              and photo identification to qualify for this program. After we
              receive these documents, we will review your application and check
              if we have inventory available. If we do, then we will contact you
              within <strong>30 days</strong>.{" "}
            </p>
            <h2 className="heading">
              <div className="wrapper">
                <span>Income Documentation</span>
              </div>
            </h2>
            <h2 className="context">
              <div className="wrapper">
                <span>
                  ​There are a number of ways to qualify by using income
                  documentation. You may show us the following: 
                </span>
              </div>
            </h2>
            <h1>
              Copy of last year’s federal tax return with federal schedule E
              that reflects current income 
            </h1>
            <h1>Unemployment Benefits letter</h1>
            <h1>Social Security Benefits letter</h1>
            <h1>Self employment ledger documentation</h1>
            <h1>
              Wages and tax statement(W-2 and/ or 1099, including 1099 MISC,
              1099G, 1099R, 1099SSA, 1099DIV, 1099SS, 1099INT).
            </h1>
            <h2 className="heading">
              <div className="wrapper">
                <span>Photo Identification</span>
              </div>
            </h2>
            <h2 className="context">
              <div className="wrapper">
                <span>​Examples:</span>
              </div>
            </h2>
            <h1>School ID</h1>
            <h1>Veteran ID card</h1>
            <h1>Driver's license</h1>
            <h1>passport</h1>
            <h2 className="heading">
              <div className="wrapper">
                <span>How do I send these documents?</span>
              </div>
            </h2>
            <p className="desc">
              Please email us at tech4kidshelp@gmail.com to check for
              eligibility. Please note that whether or not these documents are
              eligible are based on the volunteers of our organization.{" "}
            </p>
          </div>
        </div>
      </section>

      <section id="footer">
        <div className="container">
          <Footer />
        </div>
      </section>

      <script src="js/smooth-scroll.js"></script>

      <script>
        var Scrollbar = window.Scrollbar;
        Scrollbar.init(document.querySelector('#about-page'));
      </script>
    </div>
  );
}

export default apply;
