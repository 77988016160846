const MobileNavigation = () => {
  return (
    <div className="mobileNavigation">
      <ul className="mobile-links">
        <li>
          <a href="/apply">Apply</a>
        </li>
        <li>
          <a href="/donate">Donate</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/faq">FAQs</a>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
      </ul>
    </div>
  );
};

export default MobileNavigation;
